
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap&subset=latin-ext');

@import '../variables';



html, body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  box-sizing: border-box;

  div.App {
    text-align: center;

    div.main-part {      
      padding: 12px 0;

      .modal {
        display: none;
        position: fixed;
        z-index: 1;
        padding-top: 68px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        // background-color: rgb(0,0,0);
        background-color: rgba(0,0,0,0.4);
      }

      .modal-content {
        margin: auto;
        border: 3px solid $sandy-brown;
        // width: 80%;
        border-radius: 3px;
        @include boxShadow2;
        max-width: 420px;
        width: 94%;
        
        .modal-main {
          background-color: $orange-yellow-crayola;

          div.saved-list {
            max-height: 400px;
            overflow-y: auto;
            overflow-x: visible;
            border: none;
            outline: none;

            ul {
              list-style-type: none;
              padding-bottom: 20px;
              margin: 0;
              padding-left: 10px;
              text-align: left;
              color: $charcoal;
              
              li {
                height: 28px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                
                button {
                  color: $primary-text-color;
                  outline: none;
                  border: none;
                  border-radius: 3px;
                  cursor: pointer;
                  padding: 4px 8px;
                  margin: 0 6px 0 0;
                  transition: background-color .15s ease-in-out;
                  &.load-btn {
                    background-color: $persian-green;
                    width: 100%;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    transition: background-color 0.2s ease-in-out;
                  }
                  &.load-btn:hover {
                    background-color: darken($persian-green, 10%);
                  }
                  &.delete-btn {
                    background-color: $burnt-sienna;
                  }
                  &.delete-btn:hover {
                    background-color: darken($burnt-sienna, 10%);
                  }
                }

                // span.item-id {
                //   margin-left: 8px;
                // }

                span.item-name {
                  margin-left: 10px;
                  font-weight: bold;
                }

                span.item-date {
                  display: block;
                  font-weight: bold;
                  font-size: 12px;
                  width: 100%;
                  max-width: 140px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
            }
          }


          div.modal-title {
            padding: 20px 16px;
            font-weight: bold;
            border-bottom: 2px solid $persian-green;
            position: relative;

            span.title {
              font-weight: bold;
            }
            span.close {
              position: absolute;
              right: 10px;
              top: 0px;
              width: 22px;
              color: $burnt-sienna;
              font-size: 28px;
              font-weight: bold;
              transition: color 0.15s ease-in-out;
      
              &:hover, &:focus {
                color: $sandy-brown;
                text-decoration: none;
                color: darken($burnt-sienna, 10%);
                cursor: pointer;
              }
            }
          }
          button.add-btn {
            background-color: $charcoal;
            border: none;
            border-radius: 4px;
            margin: 6px 0;
            color: $primary-text-color;
            padding: 8px 14px;
            font-size: 32px;
            outline: none;
            cursor: pointer;
            transition: background-color 0.15s ease-in-out;
            &:hover {
              background-color: $sandy-brown;
            }
          }

        }

      }


      div.tables {

        .grid-container {
          background-color: $orange-yellow-crayola;
          width: 100%;
          max-width: 450px;
          display: grid;
          color: $charcoal;
          $colHeight: 44px;
          $headerheight: 40px;
          $colWidth: 33.334%;

          grid-template-columns: $colWidth $colWidth $colWidth;
          grid-template-rows: 46px $headerheight $headerheight $headerheight $headerheight 6px $colHeight $colHeight $colHeight $colHeight $colHeight $colHeight $colHeight $colHeight $colHeight $colHeight;
          gap: 0px 0px;
          border-collapse: collapse;
          border-radius: 2px;
          border: 2px solid $persian-green;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          font-weight: bold;
          grid-template-areas:
            "header header header"
            "title vol-in-name vol-out-name"
            "title vol-in-input vol-out-input"
            "reagent-name for-1-probe-name probe-amount-name"
            "reagent-name for-1-probe-name probe-amount-input"
            "divider1 divider2 divider3"
            "water-name water-input water-result"
            "buffer-name buffer-input buffer-result"
            "enhancer-name enhancer-input enhancer-result"
            "primer1-name primer1-input primer1-result"
            "primer2-name primer2-input primer2-result"
            "polymerase-name polymerase-input polymerase-result"
            "dntps-name dntps-input dntps-result"
            "mgcl2-name mgcl2-input mgcl2-result"
            "dna-name dna-input dna-result"
            "info difference action-button";
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            vertical-align: middle;
            line-height: normal;

            & > div {
              vertical-align: middle;
              margin: auto 0;
            }

            div.app-header {
              grid-area: header;
              background-color: $charcoal;
              color: $orange-yellow-crayola;
              font-weight: 100;
              font-size: 34px;
              height: 100%;
              vertical-align: middle;
              line-height: 50px;
              width: 100%;
            }

            .divider1 {
              border-bottom: 2px solid $persian-green;
            }
    
            .divider2 {
              border-bottom: 2px solid $persian-green;
            }
    
            .divider3 {
              border-bottom: 2px solid $persian-green;
            }
    
            .vol-in-name {
              grid-area: vol-in-name;
            }
            
            .vol-out-name {
              grid-area: vol-out-name;
            }
            
            .vol-in-input {
              grid-area: vol-in-input;
            }
            
            .vol-out-input {
              grid-area: vol-out-input;
            }
            
            .title {
              grid-area: title;
            }
            
            .reagent-name {
              grid-area: reagent-name;
            }
            
            .for-1-probe-name {
              grid-area: for-1-probe-name;
            }
            
            .probe-amount-name {
              grid-area: probe-amount-name;
              
            }
            
            .probe-amount-input {
              grid-area: probe-amount-input;
            }
            
            .water-name {
              grid-area: water-name;
            }
            
            .buffer-name {
              grid-area: buffer-name;
            }
            
            .enhancer-name {
              grid-area: enhancer-name;
            }
            
            .primer1-name {
              grid-area: primer1-name;
            }
            
            .primer2-name {
              grid-area: primer2-name;
            }
            
            .polymerase-name {
              grid-area: polymerase-name;
            }
            
            .dntps-name {
              grid-area: dntps-name;
            }
            
            .mgcl2-name {
              grid-area: mgcl2-name;
            }
            
            .dna-name {
              grid-area: dna-name;
            }
            
            .info {
              grid-area: info;
              height: 100%;
              border-top: 2px solid $persian-green;
              div {
                margin: 10px 0;
              }
            }
            
            .water-input {
              grid-area: water-input;
            }
            
            .buffer-input {
              grid-area: buffer-input;
            }
            
            .enhancer-input {
              grid-area: enhancer-input;
            }
            
            .primer1-input {
              grid-area: primer1-input;
            }
            
            .primer2-input {
              grid-area: primer2-input;
            }
            
            .polymerase-input {
              grid-area: polymerase-input;
            }
            
            .dntps-input {
              grid-area: dntps-input;
            }
            
            .mgcl2-input {
              grid-area: mgcl2-input;
            }
            
            .dna-input {
              grid-area: dna-input;
            }
            
            .difference {
              grid-area: difference;
              border-top: 2px solid $persian-green;
              height: 100%;
              div {
                margin: 10px 0;
              }
            }
            
            .water-result {
              grid-area: water-result;
            }
            
            .buffer-result {
              grid-area: buffer-result;
            }
            
            .enhancer-result {
              grid-area: enhancer-result;
            }
            
            .primer1-result {
              grid-area: primer1-result;
            }
            
            .primer2-result {
              grid-area: primer2-result;
            }
            
            .polymerase-result {
              grid-area: polymerase-result;
            }
            
            .dntps-result {
              grid-area: dntps-result;
            }
            
            .mgcl2-result {
              grid-area: mgcl2-result;
            }
            
            .dna-result {
              grid-area: dna-result;
            }
            
            div.action-button {
              grid-area: action-button;
              border-top: 2px solid $persian-green;
              height: 100%;
              div {
               margin: 1% 0;
              }
              button.btn {
                background-color: $charcoal;
                border: none;
                color: $primary-text-color;
                padding: 10px 18px;
                font-size: 16px;
                outline: none;
                cursor: pointer;
                transition: background-color 0.15s ease-in-out;
              }
              button.btn:hover {
                background-color: $sandy-brown;
              }
            }
        } 
      }
    }
  }
}
