
@import '../variables';

input.field-input {
    padding: 6px;
    outline: none;
    border: 2px solid $persian-green;
    border-radius: 3px;
    text-align: center;
    width: 60px;
    font-weight: bold;
    font-size: 16px;
}
